import { FetchRequest } from '@rails/request.js'

// Request.JS is used to process Turbo Stream responses - https://github.com/rails/request.js

// TurboClient takes a form HTML element,
// processes the request based on the form method and action,
// then using perform, receives the response and redirects to the response URL using Turbo.

export default class TurboClient {
  request: any // library is not typed yet
  options: {
    method?: string
    actionUrl?: string
    redirectNewWindow?: boolean
    extraFormData?: Record<string, string>
  }

  constructor(formElement: HTMLFormElement, options: TurboClient['options'] = {}) {
    const formData = new FormData(formElement)

    const actionUrl = options.actionUrl || formElement.action
    const method = options.method || formElement.method

    if (options.extraFormData) {
      for (const [key, value] of Object.entries(options.extraFormData)) {
        formData.append(key, value)
      }
    }

    this.request = new FetchRequest(method, actionUrl, {
      responseKind: 'turbo-stream',
      ...(method !== 'get' && { body: formData }),
    })

    this.options = options
  }

  async perform() {
    const fetchResponse = await this.request.perform()

    // Turbo stream is only rendered if response is 200:
    // https://github.com/rails/request.js/blob/dd0369b921b135fa0c946983b83a259f7d6da0de/src/fetch_request.js#L28-L30
    // However, we also need to support 422 for form validations:
    if (fetchResponse.unprocessableEntity && fetchResponse.isTurboStream) {
      await fetchResponse.renderTurboStream()
    }

    if (fetchResponse.response?.redirected && fetchResponse.response.url) {
      if (this.options.redirectNewWindow) {
        window.open(fetchResponse.response.url)
      } else {
        Turbo.visit(fetchResponse.response.url)
      }
    }

    return fetchResponse
  }
}
