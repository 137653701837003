import { Controller } from '@hotwired/stimulus'
import TurboClient from '../api/TurboClient'
import { GoogleCaptchaV3Service } from '../api/Google/captchaV3.service'

export default class extends Controller {
  static values = { siteKey: String }

  submit(event) {
    event.preventDefault()
    const form = this.element

    if (!form.checkValidity()) {
      form.reportValidity()
      return
    }

    const button = event.currentTarget
    const siteKey = this.siteKeyValue
    button.disabled = true

    // https://help.mouseflow.com/en/articles/4274450-forms-with-no-successful-submits
    const formId = form.id
    if (formId && window._mfq) {
      window._mfq.push(['formSubmitAttempt', `#${form.id}`])
    }

    GoogleCaptchaV3Service.verifyCaptcha(token => {
      let turboClient

      if (token) {
        turboClient = new TurboClient(form, {
          extraFormData: {
            'g-recaptcha-response': token,
          },
        })
      } else {
        turboClient = new TurboClient(form)
      }
      turboClient.perform().then(fetchResponse => {
        const isRedirect =
          fetchResponse.response?.redirected ||
          fetchResponse.response?.headers?.get('X-TURBO-STREAM-REDIRECT-TO')

        // when redirecting, keep the button disabled
        if (!isRedirect) {
          button.disabled = false
        }

        if (formId && window._mfq) {
          if (isRedirect || fetchResponse.ok) {
            window._mfq.push(['formSubmitSuccess', `#${form.id}`])
          } else {
            window._mfq.push(['formSubmitFailure', `#${form.id}`])
          }
        }
      })
    }, siteKey)
  }
}
