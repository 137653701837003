import { Controller } from '@hotwired/stimulus'

// Used to filter configurations for applications landing pages
// See landing_pages/application_landing_pages/sections/_configuration.html.erb
export default class extends Controller {
  static targets = ['button', 'overview']

  connect() {
    this.select({ target: this.buttonTargets[0] })
  }

  select({ target }) {
    const { label } = target.dataset
    if (label === this.activeLabel) return

    this.overviewTargets.forEach(overview => {
      overview.classList.toggle('configuration-overview--active', overview.dataset.label === label)
    })
    this.buttonTargets.forEach(button => {
      button.classList.toggle('configuration-tabs__button--active', button.dataset.label === label)
    })
    this.setActiveBarToActiveTab(target)
  }

  setActiveBarToActiveTab(target) {
    const activeBar = this.element.querySelector('.configuration-tabs__active-bar')
    const left = target.offsetLeft
    const width = target.offsetWidth

    activeBar.style.left = `${left}px`
    activeBar.style.width = `${width}px`
  }

  get activeLabel() {
    return this.activeOverview?.dataset?.label
  }

  get activeOverview() {
    return this.overviewTargets.find(overview => overview.classList.contains('active'))
  }
}