import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'
import { breakpoints } from '../constants/Layout'

/**
 * This controller is meant to be used in turbo_frame tags in conjunction with
 * the request.variant option in the Rails controller.
 *
 * It adds the `mobile=true` to the `url` if the breakpoint is smaller than the value
 * passed and sets the new url as the src of the turbo_frame.
 *
 * @example
 *
 * On .erb:
 * <%= turbo_frame_tag(
 *    :examples,
 *    src: examples_path,
 *    data: {
 *      controller: 'turbo-frame-variant',
 *      'turbo-frame-variant-breakpoint-label-value': 'large'
 *    }
 * ) %>
 *
 * On examples_controller.rb:
 *
 * def index
 *  request.variant = :mobile if params[:mobile]
 * end
 */
export default class extends Controller {
  static values = {
    breakpoint: { type: String, default: 'navMainLarge' }
  }

  connect() {
    this.initialUrl = this.getCurrentUrl()

    this.swapVariantBasedOnScreenWidth(window.innerWidth)

    useWindowResize(this) // Will also call windowResize immediately on connect
  }

  windowResize({ width }) {
    this.swapVariantBasedOnScreenWidth(width)
  }

  swapVariantBasedOnScreenWidth(width) {
    const isMobile = width <= breakpoints[this.breakpointValue]

    if (isMobile && !this.currentUrlHasMobileParam()) {
      const mobileUrl = this.initialUrlWithMobileParam()

      this.setCurrentUrl(mobileUrl)
    } else if (!isMobile && this.currentUrlHasMobileParam()) {
      this.setCurrentUrl(this.initialUrl)
    }
  }

  getCurrentUrl() {
    return new URL(this.element.getAttribute('src'))
  }

  setCurrentUrl(url) {
    this.element.setAttribute('src', url.href)
  }

  initialUrlWithMobileParam() {
    const initialUrlCopy = new URL(this.initialUrl)

    initialUrlCopy.searchParams.append('mobile', true)

    return initialUrlCopy
  }

  currentUrlHasMobileParam() {
    return this.getCurrentUrl().searchParams.get('mobile')
  }
}
